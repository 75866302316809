import { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Box, Text } from 'theme-ui';
import Section from './Section';
import { Card, CardContainer } from 'components/ui/cards';
import { CImage } from 'components/ui/CImage';
import { LinkButtonArrowHollow } from 'components/ui/links';
import { useResumeVerb } from 'context/resume-verb';
import { usePrefersReducedMotion } from 'hooks/use-is-media-query';
import { useInterval } from 'lib/hooks';

type Categories = 'creative' | 'modern' | 'professional' | 'simple';

export default function OptimizedDesigns() {
  const { rVerbExp, rVerbCap } = useResumeVerb();
  const prefersReducedMotion = usePrefersReducedMotion();
  const [category, setCategory] = useState<Categories>('creative');
  const [isRunning, setIsRunning] = useState(
    prefersReducedMotion ? false : true
  );
  const DELAY = 4000;
  const TRANSITION = 1000;

  useInterval(
    () => {
      switch (category) {
        case 'creative':
          setCategory('modern');
          return;
        case 'modern':
          setCategory('professional');
          return;
        case 'professional':
          setCategory('simple');
          return;
        case 'simple':
          setCategory('creative');
          return;
        default:
          setCategory('simple');
          return;
      }
    },
    isRunning ? DELAY : null
  );

  return (
    <Section
      title="Optimized Designs"
      heading="Designed for Success"
      intro={`Choose from 12 uniquely designed ${rVerbExp} templates.`}
    >
      <CardContainer>
        <Card
          column={[12]}
          isDark={true}
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            bg: getBackgroundColor(category),
            transition: `background ${TRANSITION}ms`,
            '@media (prefers-reduced-motion: reduce)': {
              transition: 'none',
            },
          }}
        >
          <Box
            sx={{
              width: 340,
              flexShrink: 0,
              flexGrow: 0,
              ml: [32, 48],
              pt: [48, 48, 133],
            }}
          >
            <Text
              variant="displayMedium"
              sx={{
                mb: 24,
                color: category === 'creative' ? 'text' : 'textLighter',
                cursor: 'pointer',
                transition: `color ${TRANSITION}ms`,
                '@media (prefers-reduced-motion: reduce)': {
                  transition: 'none',
                },
              }}
              onClick={() => {
                setIsRunning(false);
                setCategory('creative');
              }}
            >
              Creative
            </Text>
            <Text
              variant="displayMedium"
              sx={{
                mb: 24,
                color: category === 'modern' ? 'text' : 'textLighter',
                cursor: 'pointer',
                transition: `color ${TRANSITION}ms`,
                '@media (prefers-reduced-motion: reduce)': {
                  transition: 'none',
                },
              }}
              onClick={() => {
                setIsRunning(false);
                setCategory('modern');
              }}
            >
              Modern
            </Text>
            <Text
              variant="displayMedium"
              sx={{
                mb: 24,
                color: category === 'professional' ? 'text' : 'textLighter',
                cursor: 'pointer',
                transition: `color ${TRANSITION}ms`,
                '@media (prefers-reduced-motion: reduce)': {
                  transition: 'none',
                },
              }}
              onClick={() => {
                setIsRunning(false);
                setCategory('professional');
              }}
            >
              Professional
            </Text>
            <Text
              variant="displayMedium"
              sx={{
                mb: 24,
                color: category === 'simple' ? 'text' : 'textLighter',
                cursor: 'pointer',
                transition: `color ${TRANSITION}ms`,
                '@media (prefers-reduced-motion: reduce)': {
                  transition: 'none',
                },
              }}
              onClick={() => {
                setIsRunning(false);
                setCategory('simple');
              }}
            >
              Simple
            </Text>
            <LinkButtonArrowHollow
              href={`${
                process.env.NEXT_PUBLIC_APP_SETUP_URL
              }?templateid=${getTemplateId(category)}`}
              arrowColor="white"
              sx={{
                color: 'sWhite',
                borderColor: 'sWhite',
                mt: [12, 24],
                mb: [24, 24],
              }}
            >
              Use This Template
            </LinkButtonArrowHollow>
          </Box>
          <Box
            sx={{
              pt: [32, 68],
              pl: [32, 16, 80],
            }}
          >
            <SwitchTransition>
              <CSSTransition
                key={category}
                // @ts-ignore this is from the docs...
                addEndListener={(node, done) =>
                  node.addEventListener('transitionend', done, false)
                }
                timeout={prefersReducedMotion ? 0 : 1000}
                classNames="template"
              >
                <CImage
                  alt={`${rVerbCap} template`}
                  htmlWidth={874 * 2}
                  htmlHeight={710 * 2}
                  sizes="(max-width: 768px) 100vw, (max-width: 1390px) 50vw, 874px"
                  srcSetSizes={[375, 750, 1748]}
                  id={getImageId(category)}
                  loading="lazy"
                  sx={{ display: 'block' }}
                />
              </CSSTransition>
            </SwitchTransition>
            <style>
              {`
                .template-enter{
                   opacity: 0;
                }
                .template-exit{
                   opacity: 1;
                }
                .template-enter-active{
                   opacity: 1;
                }
                .template-exit-active{
                   opacity: 0;
                }
                .template-enter-active,
                .template-exit-active {
                   transition: opacity ${TRANSITION / 2}ms;
                }
                @media (prefers-reduced-motion: reduce) {
                  .template-enter-active,
                  .template-exit-active {
                     transition: none !important;
                  }
                }
              `}
            </style>
          </Box>
        </Card>
      </CardContainer>
    </Section>
  );
}

function getBackgroundColor(category: Categories): string {
  switch (category) {
    case 'creative':
      return 'rgb(164, 101, 229)';
    case 'modern':
      return 'rgb(115, 19, 231)';
    case 'professional':
      return 'rgb(19, 63, 123)';
    case 'simple':
      return 'rgb(59, 77, 239)';
    default:
      return 'blue';
  }
}

function getTemplateId(category: Categories): string {
  switch (category) {
    case 'creative':
      return 'georgia';
    case 'modern':
      return 'pender';
    case 'professional':
      return 'parker';
    case 'simple':
      return 'san-juan';
    default:
      return 'blue';
  }
}

function getImageId(category: Categories): string {
  switch (category) {
    case 'creative':
      return 'landing-pages/creative-resume-template_qpngws.png';
    case 'modern':
      return 'landing-pages/modern-resume-tempalate.png';
    case 'professional':
      return 'landing-pages/professional-resume-template_kafurk.png';
    case 'simple':
      return 'landing-pages/simple-resume-template.png';
    default:
      return 'landing-pages/simple-resume-template.png';
  }
}
