// The legacy Cloudinary lib was huge and imported all of lodash...
// The new one is smaller but has a stupidly complicated interface.
// Since our needs are simple, I just wrote a very basic and hard coded implentation here...

export type TransformationOptions = {
  quality?: string | number;
  fetchFormat?: string;
  crop?: string;
  width?: string | number;
  height?: string | number;
  format?: string;
  gravity?: string;
  x?: number;
  y?: number;
};

export function getCloudinaryImageUrl(
  id: string,
  options: TransformationOptions
) {
  const transformations = optionsToString({
    quality: 'auto',
    fetchFormat: 'auto',
    crop: 'fill',
    ...options,
  });
  return `https://assets.standardresume.co/${transformations}/v1/${id}`;
}

export function getCloudinaryFetchUrl(
  url: string,
  options: TransformationOptions
) {
  const transformations = optionsToString({
    quality: 'auto',
    fetchFormat: 'auto',
    crop: 'fill',
    ...options,
  });
  return `https://assets.standardresume.co/image/fetch/${transformations}/${encodeURIComponent(
    url
  )}`;
}

export function getCloudinaryVideoUrl(
  id: string,
  options: TransformationOptions
) {
  const transformations = optionsToString({
    crop: 'fill',
    quality: 'auto',
    ...options,
  });
  return `https://assets.standardresume.co/video/upload/${transformations}/v1/${id}.${options.format}`;
}

export function getCloudinaryVideoPosterUrl(
  id: string,
  options: TransformationOptions
) {
  const transformations = optionsToString({
    crop: 'fill',
    quality: 'auto:low',
    width: 1000,
    ...options,
  });
  return `https://assets.standardresume.co/video/upload/${transformations}/v1/${id}.webp`;
}

// Mapping of transformation options to their URL string value
const map = {
  quality: 'q_',
  fetchFormat: 'f_',
  crop: 'c_',
  width: 'w_',
  height: 'h_',
  gravity: 'g_',
  x: 'x_',
  y: 'y_',
};

function optionsToString(options: TransformationOptions) {
  let transformations = '';

  for (const [key, value] of Object.entries(options)) {
    if (map[key]) {
      transformations += `${map[key]}${value},`;
    }
  }

  // Remove extra comma
  transformations = transformations.slice(0, -1);

  return transformations;
}
