import { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Box, Text } from 'theme-ui';
import Section from './Section';
import { Card, CardContainer } from 'components/ui/cards';
import { CImage } from 'components/ui/CImage';
import { LinkButtonArrow } from 'components/ui/links';
import { useResumeVerb } from 'context/resume-verb';
import { usePrefersReducedMotion } from 'hooks/use-is-media-query';
import { useInterval } from 'lib/hooks';

export default function TrustedTemplates() {
  const { rVerbCap } = useResumeVerb();
  const prefersReducedMotion = usePrefersReducedMotion();
  const [state, setState] = useState(0);
  const [isRunning, setIsRunning] = useState(
    prefersReducedMotion ? false : true
  );
  const DELAY = 5000;

  useInterval(
    () => {
      if (state === 2) {
        setState(0);
      } else {
        setState(state + 1);
      }
    },
    isRunning ? DELAY : null
  );

  return (
    <Section
      title="Trusted Templates"
      heading="Hiring Manager Approved"
      intro="We work with hiring managers to design and review our templates so you can apply with confidence."
    >
      <CardContainer>
        <Card
          column={[12]}
          isDark={true}
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            py: [32, 64],
            background:
              'linear-gradient(90deg, #263BFF 11.12%, #AC42FF 92.73%)',
          }}
        >
          <Box
            sx={{
              width: ['100%', '50%'],
              pl: [24, 48, 100],
              pr: [24, 48],
            }}
          >
            <SwitchTransition>
              <CSSTransition
                key={state}
                // @ts-ignore this is from the docs...
                addEndListener={(node, done) =>
                  node.addEventListener('transitionend', done, false)
                }
                timeout={prefersReducedMotion ? 0 : 700}
                classNames="testimonial"
              >
                <Testimonials index={state} />
              </CSSTransition>
            </SwitchTransition>
            <Indicator
              index={state}
              setState={setState}
              setIsRunning={setIsRunning}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: ['center'],
              width: ['100%', '50%'],
              mt: [48, 0],
            }}
          >
            <CImage
              id="landing-pages/hiring-manager-logos-2.png"
              alt="Hiring manager"
              htmlWidth={1004}
              htmlHeight={468}
              sizes="(max-width: 768px) 100vw, (max-width: 1300px) 50vw, 502px"
              srcSetSizes={[375, 750, 1004]}
              loading="lazy"
              sx={{
                width: ['80%', '80%', '80%'],
                maxWidth: 502,
                height: 'auto',
                mb: 32,
              }}
            />
          </Box>
        </Card>
        <Box
          sx={{
            gridColumn: '1 / -1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LinkButtonArrow
            href={`${process.env.NEXT_PUBLIC_APP_SETUP_URL}`}
            sx={{
              bg: 'sBrandBlue',
              color: 'sWhite',
              'svg path': { stroke: 'sWhite' },
            }}
          >
            {`Build My ${rVerbCap}`}
          </LinkButtonArrow>
        </Box>
      </CardContainer>
      <style>
        {`
        .testimonial-enter{
           opacity: 0;
        }
        .testimonial-exit{
           opacity: 1;
        }
        .testimonial-enter-active{
           opacity: 1;
        }
        .testimonial-exit-active{
           opacity: 0;
        }
        .testimonial-enter-active,
        .testimonial-exit-active {
           transition: opacity 700ms;
        }
        @media (prefers-reduced-motion: reduce) {
          .testimonial-enter-active,
          .testimonial-exit-active {
             transition: none;
          }
        }
      `}
      </style>
    </Section>
  );
}

function Testimonials({ index }: { index: number }) {
  if (index === 0) {
    return <GergelyTestimonial />;
  } else if (index === 1) {
    return <MikeTestimonial />;
  } else {
    return <MartinTestimonial />;
  }
}

function GergelyTestimonial() {
  return (
    <Testimonial
      photoId="landing-pages/hiring-manager-uber-gergely.png"
      testimonial="Most resume sites create resumes job seekers find beautiful, but hiring managers find difficult to read. Standard Resume is probably the only exception."
      name="Gergely Orosz"
      title="Hiring Manager & Author"
    />
  );
}

function MikeTestimonial() {
  return (
    <Testimonial
      photoId="landing-pages/hiring-manager-slack-mike.png"
      testimonial="I look at hundreds of resumes  and Standard Resume’s templates  are some of the easiest to read."
      name="Mike Clapson"
      title="Hiring Manager at Slack"
    />
  );
}

function MartinTestimonial() {
  return (
    <Testimonial
      photoId="landing-pages/hiring-manager-capsule-martin.png"
      testimonial="In a few minutes you can make a resume that’s better designed than 99% of the resumes out there."
      name="Martin Pedrick"
      title="Hiring Manager at Capsule"
    />
  );
}

function Testimonial({
  photoId,
  testimonial,
  name,
  title,
}: {
  photoId: string;
  testimonial: string;
  name: string;
  title: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CImage
        alt="Hiring manager"
        htmlWidth={202}
        htmlHeight={182}
        id={photoId}
        loading="lazy"
        sx={{
          width: 101,
          height: 91,
          mb: 32,
        }}
      />
      <Text
        variant="displayXSmall"
        sx={{
          fontWeight: 'medium',
          textAlign: 'center',
          minHeight: [136, 145],
        }}
      >
        “{testimonial}”
      </Text>
      <Text variant="emphasis" sx={{ textAlign: 'center' }}>
        {name}
      </Text>
      <Text sx={{ textAlign: 'center', mb: 32 }}>{title}</Text>
    </Box>
  );
}

function Indicator({
  index,
  setState,
  setIsRunning,
}: {
  index: number;
  setState: (number) => void;
  setIsRunning: (boolean) => void;
}) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        onClick={() => {
          setIsRunning(false);
          setState(0);
        }}
        sx={{
          width: 8,
          height: 8,
          borderRadius: 999,
          mx: 6,
          bg: index === 0 ? 'text' : 'textLighter',
        }}
      />
      <Box
        onClick={() => {
          setIsRunning(false);
          setState(1);
        }}
        sx={{
          width: 8,
          height: 8,
          borderRadius: 999,
          mx: 6,
          bg: index === 1 ? 'text' : 'textLighter',
        }}
      />
      <Box
        onClick={() => {
          setIsRunning(false);
          setState(2);
        }}
        sx={{
          width: 8,
          height: 8,
          borderRadius: 999,
          mx: 6,
          bg: index === 2 ? 'text' : 'textLighter',
        }}
      />
    </Box>
  );
}
