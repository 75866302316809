import { Box, Text } from 'theme-ui';
import Section from './Section';
import { Card, CardContainer } from 'components/ui/cards';
import { CImage } from 'components/ui/CImage';
import {
  CheckCircleIcon,
  ImportIcon,
  ParagraphsIcon,
  SquaresIcon,
} from 'components/ui/icons';
import { LinkButtonArrowHollow } from 'components/ui/links';
import { useResumeVerb } from 'context/resume-verb';

export default function ResumeBuilder() {
  const { rVerb, rVerbCap, rVerbExpCap } = useResumeVerb();
  return (
    <Section
      title={`${rVerbExpCap} Builder`}
      heading="Dead Simple"
      intro={`Creating a ${rVerb} that follows best practices and impresses hiring managers has never been easier.`}
    >
      <CardContainer>
        <Card
          column={[12]}
          as="section"
          isDark={true}
          sx={{
            background: '#0D0E12',
            display: 'flex',
            flexDirection: ['column', 'row'],
          }}
        >
          <Box
            variant="grids.item"
            sx={{
              flexShrink: 0,
              width: ['auto', 350, 430],
              mt: [0, 24],
            }}
          >
            <ListItem
              icon={<ParagraphsIcon size={40} color="#EB2AE3" />}
              title="Automatic formatting"
            />
            <ListItem
              icon={<SquaresIcon size={40} color="#558FFF" />}
              title="Change & customize templates"
            />
            <ListItem
              icon={<CheckCircleIcon size={40} color="#79EB20" />}
              title="Built in best practices"
            />
            <ListItem
              icon={<ImportIcon size={40} color="#21FCD4" />}
              title="LinkedIn Import"
            />
            <LinkButtonArrowHollow
              href={`${process.env.NEXT_PUBLIC_APP_SETUP_URL}`}
              arrowColor="white"
              sx={{
                color: 'sWhite',
                borderColor: 'sWhite',
                mt: [0, 32],
                mb: [24, 0],
              }}
            >
              {`Build My ${rVerbCap}`}
            </LinkButtonArrowHollow>
          </Box>
          <Box>
            <CImage
              alt={`Professional ${rVerb} builder app screenshot.`}
              htmlWidth={1872}
              htmlHeight={1480}
              sizes="(max-width: 768px) 100vw, (max-width: 1400px) 63vw, 941px"
              srcSetSizes={[375, 750, 1882]}
              id="landing-pages/resume-designer-app_zth4pv.png"
              loading="lazy"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: '0 0',
              }}
            />
          </Box>
        </Card>
      </CardContainer>
    </Section>
  );
}

function ListItem({ icon, title }: { icon: React.ReactNode; title: string }) {
  return (
    <Box sx={{ display: 'flex', mb: [24, 32] }}>
      <Box sx={{ flexShrink: 0, mr: 12 }}>{icon}</Box>
      <Box>
        <Text variant="displayXSmall" sx={{ mb: 8, pt: 5 }}>
          {title}
        </Text>
      </Box>
    </Box>
  );
}
