import Head from 'next/head';
import { Image, ThemeUIStyleObject } from 'theme-ui';
import { getCloudinaryImageUrl, TransformationOptions } from 'lib/cloudinary';

type Props = {
  alt: string;
  htmlHeight: number;
  htmlWidth: number;
  id: string;
  loading?: 'eager' | 'lazy';
  sizes?: string;
  srcSetSizes?: number[];
  sx?: ThemeUIStyleObject;
  transformations?: TransformationOptions;
  preload?: boolean;
  variant?: string;
};

export function CImage({
  alt,
  htmlHeight,
  htmlWidth,
  id,
  loading,
  sizes,
  srcSetSizes,
  sx,
  transformations = {},
  preload,
  variant,
}: Props) {
  return (
    <>
      <Image
        alt={alt}
        height={htmlHeight}
        loading={loading || null}
        sizes={sizes || null}
        src={getCloudinaryImageUrl(id, {
          width: htmlWidth,
          height: htmlHeight,
          ...transformations,
        })}
        srcSet={
          srcSetSizes ? getSrcSet(id, srcSetSizes, transformations) : null
        }
        sx={sx}
        variant={variant}
        width={htmlWidth}
      />
      {preload && (
        <Head>
          <link
            rel="preload"
            as="image"
            href={
              srcSetSizes
                ? undefined
                : getCloudinaryImageUrl(id, {
                    width: htmlWidth,
                    height: htmlHeight,
                    ...transformations,
                  })
            }
            // @ts-ignore attribute not in TS definition
            imagesrcset={
              srcSetSizes
                ? getSrcSet(id, srcSetSizes, transformations)
                : undefined
            }
            imagesizes={sizes || undefined}
          />
        </Head>
      )}
    </>
  );
}

export function getSrcSet(
  id: string,
  srcSetSizes: number[],
  transformations: TransformationOptions
) {
  let srcSet = '';

  srcSetSizes.forEach((size, index) => {
    const url = getCloudinaryImageUrl(id, { ...transformations, width: size });
    if (index !== 0) srcSet += ', ';
    srcSet += `${url} ${size}w`;
  });

  return srcSet;
}
