import { Box } from 'theme-ui';

type Props = {
  title: string;
  heading: string;
  intro?: string;
  children: React.ReactNode;
};

export default function Section({ title, heading, intro, children }: Props) {
  return (
    <Box as="section" sx={{ mb: [100, 140, 180] }}>
      <Box
        variant="layout.content"
        as="header"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: [28, 39],
        }}
      >
        <Box
          as="h2"
          sx={{
            fontSize: [14, 16],
            color: 'pink',
            fontFamily: 'monospace',
            fontWeight: 500,
            mb: 19,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Box>
        <Box
          variant="text.displayMedium"
          sx={{ mb: 15, maxWidth: 616, textAlign: 'center' }}
        >
          {heading}
        </Box>
        {intro && (
          <Box
            variant="text.title"
            sx={{
              textAlign: 'center',
              color: 'textLight',
              maxWidth: 505,
            }}
          >
            {intro}
          </Box>
        )}
      </Box>
      {children}
    </Box>
  );
}
