import { Box } from 'theme-ui';
import Section from './Section';
import { Card, CardContainer } from 'components/ui/cards';
import { CImage } from 'components/ui/CImage';
import { SVG } from 'components/ui/icons';
import { LinkButtonArrow } from 'components/ui/links';
import { useResumeVerb } from 'context/resume-verb';

export default function WebResume({
  isLinkedInLanding,
}: {
  isLinkedInLanding?: boolean;
}) {
  const { rVerb, rVerbCap } = useResumeVerb();
  return (
    <Section
      title={`Web ${rVerbCap}`}
      heading="Ditch LinkedIn"
      intro={`Sharing your profile for a referral? Use your web ${rVerb} instead of a page polluted with ads, activity, and strangers.`}
    >
      <CardContainer>
        <Card
          column={[12, 6]}
          sx={{ bg: isLinkedInLanding ? 'background' : 'sButtonGrayDark' }}
        >
          <CImage
            alt="A LinkedIn profile screenshot"
            htmlWidth={1318}
            htmlHeight={461}
            sizes="(max-width: 768px) 100vw, (max-width: 1100px) 46vw, 659px"
            srcSetSizes={[375, 750, 1318]}
            id="landing-pages/linkedin-profile.png"
            loading="lazy"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: '0 0',
              borderRadius: 'large',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: [12, 27],
              mb: [24, 0],
            }}
          >
            <CrossIcon />
          </Box>
        </Card>
        <Card
          column={[12, 6]}
          sx={{ bg: isLinkedInLanding ? 'background' : 'sButtonGrayDark' }}
        >
          <CImage
            alt={`A web ${rVerb}, built by Standard Resume`}
            htmlWidth={1318}
            htmlHeight={461}
            sizes="(max-width: 768px) 100vw, (max-width: 1100px) 46vw, 659px"
            srcSetSizes={[375, 750, 1318]}
            id="landing-pages/web-resume.png"
            loading="lazy"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: '0 0',
              borderRadius: 'large',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: [12, 27],
              mb: [24, 0],
            }}
          >
            <CheckIcon />
          </Box>
        </Card>
      </CardContainer>
      {!isLinkedInLanding && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 16,
          }}
        >
          <LinkButtonArrow
            href={`${process.env.NEXT_PUBLIC_APP_SETUP_URL}`}
            arrowColor="sWhite"
            sx={{
              bg: 'sBrandBlue',
              color: 'sWhite',
            }}
          >
            {`Create Your Web ${rVerbCap}`}
          </LinkButtonArrow>
        </Box>
      )}
    </Section>
  );
}

function CheckIcon() {
  return (
    <SVG viewBox="0 0 50 50" height={50} width={50}>
      <circle cx="25" cy="25" r="24" fill="#43E18F" stroke="#43E18F" />
      <path
        d="M14.2501 28L20 33.7499L37.7499 16"
        stroke="black"
        strokeWidth="2"
      />
    </SVG>
  );
}

function CrossIcon() {
  return (
    <SVG viewBox="0 0 50 50" height={50} width={50}>
      <circle cx="25" cy="25" r="24" fill="#FB1064" stroke="#FB1064" />
      <path
        d="M35.5 35.5L14.5 14.5M14.5 35.5L35.5 14.5"
        stroke="black"
        strokeWidth="2"
      />
    </SVG>
  );
}
