import { Box } from 'theme-ui';
import Section from './Section';
import { LinkButtonArrow } from 'components/ui/links';
import { useResumeVerb } from 'context/resume-verb';

export default function GetStarted() {
  const { rVerbCap } = useResumeVerb();
  return (
    <Section
      title="Get Started"
      heading={`Build a ${rVerbCap} Hiring Managers Will Love.`}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <LinkButtonArrow
          href={`${process.env.NEXT_PUBLIC_APP_SETUP_URL}`}
          sx={{
            bg: 'sBrandBlue',
            color: 'sWhite',
            'svg path': { stroke: 'sWhite' },
          }}
        >
          Get Started
        </LinkButtonArrow>
      </Box>
    </Section>
  );
}
