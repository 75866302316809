import {
  Box,
  BoxProps,
  SxProps,
  SxStyleProp,
  ThemeUIStyleObject,
} from 'theme-ui';
import Dark from './Dark';
import { sizes } from 'styles/theme';

interface BoxPropsWithSx extends BoxProps, SxProps {}

export function CardContainer({ children, sx, ...rest }: BoxPropsWithSx) {
  const styles: SxStyleProp = {
    // Remove this and change components to use new 6 column grid
    gridTemplateColumns: 'repeat(12, 1fr)',
    ...sx,
  };
  return (
    <Box variant="grids.full" sx={styles} as="section" {...rest}>
      {children}
    </Box>
  );
}

type Padding = 'x' | 'y' | 'xy';
interface CardProps extends BoxProps, SxProps {
  column?: number[];
  isDark?: boolean;
  padding?: Padding | undefined;
}
export function Card({
  children,
  column,
  isDark,
  padding,
  sx,
  ...rest
}: CardProps) {
  const styles: SxStyleProp = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'white',
    borderRadius: 'large',
    px: padding === 'x' || padding === 'xy' ? sizes.gridItemPadding : null,
    py: padding === 'y' || padding === 'xy' ? sizes.gridItemPadding : null,
    ...sx,
  };
  if (column) {
    styles.gridColumn = column.map((num) => 'span ' + num);
  }

  if (isDark) {
    return (
      <Dark>
        <Box sx={styles} {...rest}>
          {children}
        </Box>
      </Dark>
    );
  }

  return (
    <Box sx={styles} {...rest}>
      {children}
    </Box>
  );
}

export function SplitCard({ sx, ...rest }: CardProps) {
  const styles: ThemeUIStyleObject = {
    flexDirection: ['column', 'row'],
    justifyContent: ['normal', 'space-between'],
    ...sx,
  };
  return <Card column={[12]} sx={styles} {...rest} />;
}

export function CardHeader({ children, sx, ...rest }: BoxPropsWithSx) {
  const styles: ThemeUIStyleObject = {
    px: sizes.gridItemPadding,
    pt: sizes.gridItemPadding,
    zIndex: 1,
    ...sx,
  };
  return (
    <Box sx={styles} as="header" {...rest}>
      {children}
    </Box>
  );
}

export function CardHeading({ children, sx, ...rest }: BoxPropsWithSx) {
  const styles: ThemeUIStyleObject = {
    mb: 8,
    ...sx,
  };
  return (
    <Box as="h3" variant="text.titleEmphasis" sx={styles} {...rest}>
      {children}
    </Box>
  );
}

// This is only needed when the card has a background image
export function CardContent({
  children,
  padding,
  sx,
  ...rest
}: { padding?: Padding } & BoxPropsWithSx) {
  const styles: ThemeUIStyleObject = {
    zIndex: 1,
    px: padding === 'x' || padding === 'xy' ? sizes.gridItemPadding : null,
    py: padding === 'y' || padding === 'xy' ? sizes.gridItemPadding : null,
    ...sx,
  };
  return (
    <Box sx={styles} {...rest}>
      {children}
    </Box>
  );
}

export function CardContentWithPadding({
  children,
  sx,
  ...rest
}: BoxPropsWithSx) {
  const styles: ThemeUIStyleObject = {
    px: sizes.gridItemPadding,
    pb: sizes.gridItemPadding,
    zIndex: 1,
    ...sx,
  };
  return (
    <Box sx={styles} {...rest}>
      {children}
    </Box>
  );
}

export function CardBackground({ children, sx, ...rest }: BoxPropsWithSx) {
  const styles: ThemeUIStyleObject = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    ...sx,
  };
  return (
    <Box sx={styles} {...rest}>
      {children}
    </Box>
  );
}

export function HeaderCard({ children, sx, ...rest }: BoxPropsWithSx) {
  const styles: ThemeUIStyleObject = {
    backgroundColor: 'transparent',
    borderRadius: 0,
    px: sizes.gridItemPadding,
    pb: 12,
    ...sx,
  };
  return (
    // @ts-ignore
    <Card as="header" column={[12]} sx={styles} {...rest}>
      {children}
    </Card>
  );
}

export function HeaderCardHeading({ children, sx, ...rest }: BoxPropsWithSx) {
  const styles: ThemeUIStyleObject = {
    mb: 8,
    ...sx,
  };
  return (
    <Box as="h2" variant="text.displayMedium" sx={styles} {...rest}>
      {children}
    </Box>
  );
}

export function HeaderCardIntro({ children, sx, ...rest }: BoxPropsWithSx) {
  const styles: ThemeUIStyleObject = {
    color: 'textLighter',
    maxWidth: 600,
    ...sx,
  };
  return (
    <Box variant="text.titleEmphasis" sx={styles} {...rest}>
      {children}
    </Box>
  );
}
